/* Place to put url constants for API */

export const PATH = {
  companyProfiles: 'v1/company_profiles/',
  jobDescription: 'v1/jobs/',
  numberOfQuestions: 'v1/jobs/get-num-questions/',
  shortenLink: 'v1/links/shorten?longUrl=',
  searchJobs: 'search/jobs/',
  ipInfo: 'v1/get-geolocation?ip=',
  easyApply: 'v1/application/easy-apply/',
  appliedJobsList: 'v1/savedapplications',
  applicants: 'v1/applicants',
  applicantsRegister: 'v1/applicants/register',
  applicantsLogin: 'v1/applicants/login',
  applicantsResetPassword: 'v1/applicants/sendresetpasswordemail',
  applicantsNewPassword: 'v1/applicants/resetpassword',
  resumeParsing: 'v1/resume-parsing/file',
  processedApplications: 'v1/processedapplications',
  churchillJobRedirect: 'job/',
  churchillApplyRedirect: 'apply/',
  industries: 'search/industries/',
  jobTranslate: 'v1/translate/',
  questionTranslate: 'v1/translate/question_template/',
  jobCities: 'search/cities/',
  licenseVerification: 'v1/credentialing/validate/',
  uploadLicenseFile: 'v1/credentialing/license/upload/',
  credentialingStateFullName: 'v1/credentialing/availability/',
  credentialingJobTypeAndState: 'v1/credentialing/latest-credential-job-type-state/',
};

const DEFAULT_BASE_URL = 'https://api-staging.apploi.com/';
const DEFAULT_INTEGRATIONS_URL = 'https://ats-integrations-staging.apploi.com/';

export const apiUrl = async (activeEnvironment, domain, path) => {
  const isBrowser = typeof window !== 'undefined';
  const DOMAIN_GLOBAL = {
    baseUrl: isBrowser ? window.baseURL || process.env.BASE_URL || DEFAULT_BASE_URL : DEFAULT_BASE_URL,
    integrations: isBrowser ? window.integrationsURL || process.env.INTEGRATIONS_URL || DEFAULT_INTEGRATIONS_URL : DEFAULT_INTEGRATIONS_URL,
    /* Churchill redirect - remove when Search route is made */
    churchillRedirect: isBrowser && window.location ? `${window.location.origin}/` : '',
  };

  const url = `${DOMAIN_GLOBAL[domain]}${path ? PATH[path] : ''}`;

  console.log('API CALLED FOR', domain);

  return url;
};
